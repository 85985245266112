<template>
  <div id="school-page">
    <AppContent :isFluid="true">
      <div class="school-content">
          <b-row class="justify-content-center" align-v="center" no-gutters>
            <b-col class="text-center" cols="12" xl="3" order="3" order-xl="1">
              <img class="img-fluid pj-panda" :src="pjPanda" alt="PJ Panda" />
            </b-col>
            <b-col class="dial-col" cols="4" xl="3" order="1" order-xl="2">
              <img class="img-fluid dial" :src="dial" alt="Knob" />
            </b-col>
            <b-col
              class="text-center"
              :cols="breakpoint === 'xs' ? 12 : 8" xl="6"
              order="2" order-xl="3">
              <div class="content-wrapper">
                <div class="text-container">
                  <p class="header">Professional Development</p>
                  <ul>
                    <li>Bringing culture to the classroom</li>
                    <li>Team building activities</li>
                    <li>Online Continued education</li>
                  </ul>
                </div>

                <div class="text-container">
                  <p class="header">Online Platform</p>
                  <ul>
                    <li>Brain Boosters & Energizers</li>
                    <li>Classroom Live Stream Sessions</li>
                    <li>Mindfulness & Social Emotional Development</li>
                  </ul>
                </div>

                <div class="text-container">
                  <p class="header">Mobile Applications</p>
                  <ul>
                    <li>Virtual Homework</li>
                    <li>Audio & Visual Creation Tool</li>
                    <li>Exclusive Educational Content</li>
                  </ul>
                </div>
              </div>
              
            </b-col>
          </b-row>
      </div>
    </AppContent>
    <AppFooter class="footer-comp" />
  </div>
</template>
<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    head : {
      title : {
        inner      : 'School',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    mixins : [
      BreakpointMixin,
    ],
    computed : {
      pjPanda() {
        return require('../../assets/images/schools/schools-page-pj-panda.png');
      },
      dial() {
        return require('../../assets/images/schools/schools-page-dial.png');
      },
    },
    components : {
      'AppContent' : () => import('@/components/layout/AppContent'),
      'AppFooter'  : () => import('@/components/layout/AppFooter'),
    },
    mounted() {
      this.$analytics.fbq.event('PageView', {
        'page'     : 'schools',
        'platform' : 'website',
      });
    },
  }
</script>
<style lang="scss" scoped>
 @import "../../assets/scss/settings";

  #school-page {
    background-image: $school-bg;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding-left: 0.5rem;
      padding-bottom: 0.25rem;
      color: #fff;
      letter-spacing: 1.5px;

      &::before {
        content: '-';
        padding-right: 0.5rem;
        color: #fff;
      }
    }
  }

  .dial {
    display: inline-block;
    vertical-align: middle;
  }
  .content-wrapper {
    display: inline-block;
    width: fit-content;
    vertical-align: middle;
  }
  .text-container {
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;
    background-color: #4e8bce;
    border-radius: 1rem;
    text-align: left;
  }

  .header {
    margin-bottom: 0.25rem;
    font-size: 1.5em !important;
    letter-spacing: 2px;
    -webkit-text-stroke: 0.2px #fff;
  }

  .footer-comp {
    margin-top: auto;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .school-content {
      margin: 2rem 0;
    }
    .dial-col {
      display: none;
    }
    .text-container {
      margin-bottom: 0.85rem;
      padding: 0.5rem;
    }
    .header {
      letter-spacing: 0;
      font-size: 1.2em !important;
    }
    ul {
      li {
        font-size: 0.95em !important;
        letter-spacing: 0;
      }
    }
    .pj-panda {
      margin-top: 1rem;
      width: 50%;
      text-align: center;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .school-content {
      margin: 3rem 0;
    }
    .content-wrapper {
      margin-left: 2rem;
    }
    .text-container {
      margin-bottom: 1rem;
      padding: 0.75rem;
    }
    .header {
      letter-spacing: 0;
      font-size: 1.3em !important;
    }
    ul {
      li {
        font-size: 1.1em !important;
        letter-spacing: 0;
      }
    }
    .pj-panda {
      margin-top: 1.5rem;
      width: 40%;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .header {
      letter-spacing: 1.5px;
      font-size: 1.4em !important;
    }
    ul {
      li {
        font-size: 1.2em !important;
        letter-spacing: 1px;
      }
    }
    .pj-panda {
      margin-top: 1.5rem;
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .text-container {
      margin-bottom: 1.5rem;
      padding: 1rem 1.5rem;
    }
    .header {
      letter-spacing: 2px;
      font-size: 1.55em !important;
    }
    ul {
      li {
        font-size: 1.2em !important;
        letter-spacing: 1.5px;
      }
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    #school-page {
      background-image: $section1-bg;
    }
    .school-content {
      margin: 2rem 6rem;
    }
    .pj-panda {
      width: 100%;
    }
  }

  @media only screen and (min-width: $special) {}

  @media only screen and (min-width: $xl2-min) {
    .school-content {
      margin: 3rem 7.5rem;
    }
    .header {
      letter-spacing: 2.5px;
      font-size: 1.55em !important;
    }
    ul {
      li {
        font-size: 1.2em !important;
      }
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .school-content {
      margin: 5rem 14rem;
    }
    .text-container {
      margin-bottom: 3rem;
      padding: 1.5rem 2rem;
    }
    .pj-panda {
      width: 100%;
    }
    .dial {
      width: 95%;
    }
  }
</style>